import './KfzPrice2024StageComponentJWG.scss';

import React from 'react';

import useBreakpoint from '../../hooks/useBreakpoint';
import BarbaraNovember from '../../styles/assets/images/kfz-2024-stage/static-barbara-november.webp';
import BarbaraNovemberS from '../../styles/assets/images/kfz-2024-stage/static-barbara-november-s.webp';
import Background from '../../styles/assets/images/kfz-2024-stage/static-cars-percent-bg.webp';
import Trusted from '../../styles/assets/images/kfz-2024-stage/static-trusted-light.webp';
import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import Image from '../ui/Image/Image';

const KfzPrice2024StageComponentJWG = () => {
  const isMaxVpS = useBreakpoint('s');

  return (
    <header className={`KfzPrice2024StageComponentJWG`}>
      <div className="KfzPrice2024StageComponentJWG__inner">
        <div className="KfzPrice2024StageComponentJWG__contentWrapper">
          <h1 className="KfzPrice2024StageComponent__headline">
            Noch bis 30.11. <strong>Autoversicherung wechseln</strong> und sparen
          </h1>
          <div className="KfzPrice2024StageComponentJWG__buttonWrapper">
            <NewButtonComponent
              icon="calculator"
              linkTo={'/versicherungen/kfz-versicherung/auto/tarifrechner'}
              label="Jetzt Beitrag berechnen"
              linkType="external"
            />
            <NewButtonComponent
              icon="info"
              linkTo={'https://www.vhv.de/wf/lp/autoversicherung'}
              label="Mehr erfahren"
              variant="white"
            />
          </div>
          <div className="KfzPrice2024StageComponentJWG__trustedWrapper">
            <Image src={Trusted} />
          </div>
        </div>
        <Image extraClass="KfzPrice2024StageComponentJWG__background" src={Background} />
        <Image
          extraClass="KfzPrice2024StageComponentJWG__barbaraNovember"
          src={isMaxVpS ? BarbaraNovemberS : BarbaraNovember}
        />
      </div>
    </header>
  );
};

export default KfzPrice2024StageComponentJWG;
